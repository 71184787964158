import * as React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from "gatsby"
import '../styles/main.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout pageTitle={frontmatter.title}>
      <div class="top-bar">
        <h1 class="left-side">{frontmatter.title} Category</h1>
      </div>
      <div className="page">
        <div className="page-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <h1>Topics:</h1>
      {
        data.allMarkdownRemark.edges.map(edge => (
          <h2 className="category-header"><Link to={edge.node.frontmatter.shortname}>{edge.node.frontmatter.title}</Link></h2>
        ))
      }
    </Layout>
  )
}

export const pageQuery = graphql`
  query($shortname: String!) {
    markdownRemark(frontmatter: { shortname: { eq: $shortname } type: { eq: "category" } }) {
      html
      frontmatter {
        type
        shortname
        title
      }
    }
    
    allMarkdownRemark(
      sort: {order: ASC, fields: [frontmatter___shortname]}
      limit: 1000
      filter: { frontmatter: { category: { eq: $shortname } type: { eq: "topic" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            type
            shortname
            title
          }
        }
      }
    }
  }
`
